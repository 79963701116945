<template>
  <div>
    <div class="back-title">
      <img
        style="width: 15px"
        src="../../assets/images/goback.png"
        alt=""
        @click="toBack"
      />
      <div style="margin-right: 15px" @click="toBack">
        &nbsp;控制中心：<span v-if="$route.query.pageIndex == 1">现场检查</span>
        <span v-if="$route.query.pageIndex == 2">文件落地</span>
        <span v-if="$route.query.pageIndex == 3">考核细则</span>
        <span v-if="$route.query.pageIndex == 4">内外审检查</span>
      </div>
    </div>
    <!--现场检查1、文件检查2、考核细则3（现场检查、文件检查子页相同）-->
    <div class="_TableBox">
      <el-table
        v-if="$route.query.pageIndex != 4"
        :data="tableData.slice(start, end)"
        :height="elementHeight"
        style="width: 100%"
        @row-click="handleRowClick"
        @cell-dblclick="handleCell"
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="question" label="问题" width="300">
        </el-table-column>
        <el-table-column
          prop="auditResult"
          align="center"
          label="是否符合要求"
          width="280px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              class="auditResult_box"
              v-if="$route.query.pageIndex == 1 || $route.query.pageIndex == 2"
            >
              <div
                @click="changeResult(scope.row, 1, null)"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >
                符合
              </div>
              <div
                @click="changeResult(scope.row, 0, '不符合')"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >
                不符合
              </div>
              <div
                @click="changeResult(scope.row, 4, '不适用')"
                :class="{ auditactive: scope.row.auditResult === 4 }"
              >
                不适用
              </div>
              <div
                @click="changeResult(scope.row, 2, '纠正')"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >
                纠正
              </div>
              <div
                @click="changeResult(scope.row, 3, '改进')"
                :class="{ auditactive: scope.row.auditResult === 3 }"
              >
                改进
              </div>
            </div>
            <!-- 考核细则 -->
            <div class="auditResult_box" v-if="$route.query.pageIndex == 3">
              <div
                @click="changeResult(scope.row, 1, null)"
                :class="{ auditactive: scope.row.auditResult === 1 }"
              >
                符合
              </div>
              <div
                @click="changeResult(scope.row, 0, '不符合')"
                :class="{ auditactive: scope.row.auditResult === 0 }"
              >
                不符合
              </div>
              <div
                @click="changeResult(scope.row, 2, '纠正')"
                :class="{ auditactive: scope.row.auditResult === 2 }"
              >
                纠正
              </div>
              <div
                @click="changeResult(scope.row, 3, '改进')"
                :class="{ auditactive: scope.row.auditResult === 3 }"
              >
                改进
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="imagesCount"
          label="照片列表"
          show-overflow-tooltip
          align="center"
          width="180px
        "
        >
          <template slot-scope="scope">
            <div class="img_box" @click="openImg(scope.row)">
              <img src="../../assets/images/Examine_img/add.png" alt="" />
              <span v-show="scope.row.imagesCount === 0">请添加图片</span>
              <span
                v-show="scope.row.imagesCount > 0"
                style="margin-right: 50%"
                >{{ scope.row.imagesCount }}</span
              >
              <!-- <img src="../../assets/images/Examine_img/picture.png" alt="" /> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="lawAsk"
          label="审核意见（双击列表）"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <input
              v-if="rowId === scope.row.id && columnID === scope.column.id"
              type="text"
              @focus="compare(scope.row.lawAsk)"
              @blur="Updata(scope.row, scope.row.lawAsk)"
              v-model="scope.row.lawAsk"
            />
            <span v-else>{{ scope.row.lawAsk }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="answer" label="查看答案" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="button"
              plain
              @click="lookAnswer(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 内外审问题点4 -->
    <el-table
      v-if="$route.query.pageIndex == 4"
      :data="tableData.slice(start, end)"
      :height="elementHeight"
      style="width: 100%"
      @row-click="handleRowClick"
      @cell-dblclick="handleCell"
      id="table"
    >
      <el-table-column type="index" label="序号" width="55"> </el-table-column>
      <el-table-column prop="fileName" label="文件名称" width="150">
      </el-table-column>
      <el-table-column prop="questionRemark" label="问题" width="700px">
      </el-table-column>
      <el-table-column
        prop="auditResult"
        align="center"
        label="是否符合要求"
        width="230px"
        fixed="right"
      >
        <template slot-scope="scope">
          <div class="auditResult_box">
            <div
              @click="changeResult(scope.row, 1, null)"
              :class="{ auditactive: scope.row.auditResult === 1 }"
            >
              符合
            </div>
            <div
              @click="changeResult(scope.row, 0, '不适用')"
              :class="{ auditactive: scope.row.auditResult === 0 }"
            >
              不适用
            </div>
            <div
              @click="changeResult(scope.row, 3, '改进')"
              :class="{ auditactive: scope.row.auditResult === 3 }"
            >
              改进
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
          prop="imagesCount"
          label="照片列表"
          align="center"
          width="180px
        "
        >
          <template slot-scope="scope">
            <div class="img_box" @click="openImg(scope.row)">
              <img src="../../assets/images/Examine_img/add.png" alt="" />
              <span v-show="scope.row.imagesCount === 0">请添加图片</span>
              <span
                v-show="scope.row.imagesCount > 0"
                style="margin-right: 50%"
                >{{ scope.row.imagesCount }}</span
              >
              <img src="../../assets/images/Examine_img/picture.png" alt="" />
            </div>
          </template>
        </el-table-column> -->

      <el-table-column
        prop="answer"
        width="100px"
        label="改进措施"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            class="button"
            plain
            @click="lookAnswer(scope.row)"
            >点击查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      >
      </el-pagination>
    </div>
    <el-dialog title="查看答案" :visible.sync="dialogVisibleAsk" width="40%">
      <div  class="dialog">
        <div style="font-size: 14px">答案：{{ detailItem.answer }}</div>
        <div style="font-size: 14px">图片：</div>
        <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            :preview-src-list="srcList"
            @mouseover="overimg(index)"
          >
          </el-image>
        </span>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisibleAsk = false"
            >确 定</el-button
          >
          <el-button @click="dialogVisibleAsk = false">取 消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="查看/添加图片"
      :visible.sync="dialogVisibleimg"
      width="40%"
    >
      <div>上传图片</div>
      <el-upload
        :action="this.baseUrl + '/api/UpLoadFiles/UploadImg'"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :on-success="getaddr"
        accept=".JPG, .PNG, .JPEG,.jpg, .png, .jpeg"
        :headers="token"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已上传图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        >
        </el-image>

        <i
          class="el-icon-delete-solid"
          v-show="index === Curindex"
          @click="delImg(item)"
        ></i>
      </span>
      <span slot="footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="toPostImg">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核结果" :visible.sync="dialogVisibleresult" width="35%">
      <el-form label-width="80px" v-if="$route.query.pageIndex != 4">
        <el-form-item label="审核结果:">
          <span>{{ this.state }}</span>
        </el-form-item>
        <el-form-item label="审核意见:">
          <el-input v-model="lawAsk" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="上传照片:">
          <el-upload
            :action="this.baseUrl + '/api/UpLoadFiles/UploadImg'"
            list-type="picture-card"
            ref="upload"
            :on-preview="handlePictureCardPreview"
            :on-success="getaddr"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <div>已上传图片</div>
          <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
            <el-image
              style="width: 100px; height: 100px"
              :src="item"
              :preview-src-list="srcList"
              @mouseover="overimg(index)"
            >
            </el-image>
            <i
              class="el-icon-delete-solid"
              v-show="index === Curindex"
              @click="delImg(item)"
            ></i>
          </span>
        </el-form-item>
      </el-form>
      <el-form v-if="$route.query.pageIndex == 4">
        <el-form-item label="整改措施：">
          <el-input
            v-model="correctMeasure"
            type="textarea"
            :rows="2"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="完成情况：">
          <el-input
            v-model="completion"
            type="textarea"
            :rows="2"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <div class="date">
          <span class="demonstration">完成时间：</span>
          <el-date-picker
            v-model="completeTime"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleresult = false">取消</el-button>
        <el-button type="primary" @click="reqReview">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { baseURL, get, post, put, deleteMethod } from "../../api/http";
export default {
  data() {
    return {
      token: {},
      elementHeight: 0,
      tableData: [],
      itemListUrl: "",
      detailItem: {},
      // 分页
      start: 0,
      end: 10,
      currentPage: 1,
      curSize: 10,
      // 上传图片
      imgList: [],
      srcList: [],
      Curindex: "",
      dialogVisibleimg: false,
      imgUrl: "",
      baseUrl: "",
      // 审核
      state: "",
      tableItem: {},
      dialogVisibleresult: false,
      lawAsk: "",
      correctMeasure: "", //内外审问题点
      completion: "",
      completeTime: "",
      auditResult: 0,
      // 查看答案
      dialogVisibleAsk: false,
      columnID: "",
      curlawask: "",
      rowId: 0,
    };
  },
  mounted() {
    this.token = {
      Authorization:
        "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
    };
    this.handleUrl();
    this.getTableList();
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 115); //70是div底部距离底部的高度
      });
    },
    handleUrl() {
      this.baseUrl = baseURL[process.env.NODE_ENV + ""];
      switch (this.$route.query.pageIndex * 1) {
        case 1: //现场
          this.imgUrl = "/api/AreaAuditListImage";
          this.itemListUrl = "/api/Supervision/GetByAuditId?AuditId=";
          this.reviewUrl = "/api/Supervision/PutAreaAudit";
          break;
        case 2: //文件
          this.imgUrl = "/api/FileAuditListImage";
          this.itemListUrl = "/api/Supervision/GetByFileAuditId?FileAuditId=";
          this.reviewUrl = "/api/Supervision/PutFileAudit";
          break;
        case 3: //考核
          this.imgUrl = "/api/FileAuditListImage";
          this.itemListUrl = "/api/Supervision/GetByFileAuditId?FileAuditId=";
          this.reviewUrl = "/api/Supervision/PutFileAudit";
          break;
        case 4: //内外审
          this.imgUrl = "/api/InOutAuditListImage";
          this.itemListUrl = "/api/Supervision/GetByInOutAuditId?InOutAuditId=";
          this.reviewUrl = "/api/Supervision/PutInOutAudit";
          break;
      }
    },
    getTableList() {
      get(this.itemListUrl + this.$route.query.examId).then((resp) => {
        if (resp.code == 200) {
          this.tableData = [];
          resp.data.map((item) => {
            if (item.auditResult != 1 && item.auditResult != null)
              this.tableData.push(item);
          });
        }
      });
    },
    toBack() {
      if (this.tableData.length == 0) {
        sessionStorage.setItem("controlPageRefsh", true);
      }
      this.$router.go(-1);
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    // 现场检查
    handleRowClick(row) {
      this.rowId = row.id;
    },
    handleCell(row, column) {
      this.rowId = row.id;
      this.columnID = column.id;
    },
    // 双击审核
    compare(e) {
      this.curlawask = e;
    },
    Updata(row, e) {
      this.columnID = "";
      if (this.curlawask === e) return;
      put("/api/Supervision/PutFileAudit", row).then((res) => {
        this.$message({
          type: "success",
          message: res.code == 200 ? "更改成功" : res.message,
        });
      });
    },
    // 查看答案
    lookAnswer(row) {
      console.log(row,77);
      this.dialogVisibleAsk = true;
      this.detailItem = row;
      this.srcList = [];
      if(row.questionImageAddr){
        var imgdata = row.questionImageAddr.split(",");
        imgdata.forEach((e) => {
          this.srcList.push(this.baseUrl + "/uploads/" + e);
        });
      }
    },
    closeResultBox() {
      this.dialogVisibleResult = false;
    },
    // 图片
    openImg(e) {
      this.detailItem = e;
      this.dialogVisibleimg = true;
      this.getImage();
    },
    getImage() {
      this.srcList = [];
      get(this.imgUrl + "/" + this.detailItem.id).then((res) => {
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
      });
    },
    toPostImg() {
      this.dialogVisibleimg = false;
      this.$refs.upload.clearFiles();
      this.detailItem.imagesCount = this.imgList.length;
    },
    overimg(index) {
      this.Curindex = index;
    },
    handlePictureCardPreview() {
      this.dialogVisiblebig = true;
    },
    async getaddr(res) {
      var data = {
        ImageRemark: "",
        ImageAddress: this.baseUrl + "/uploads/" + res.value,
      };
      switch (this.$route.query.pageIndex * 1) {
        case 1:
          data.AuditListId = this.detailItem.id;
          data.saveUserNo = this.$store.state.userInfo.userNo;
          data.saveUserName = this.$store.state.userInfo.userName;
          break;
        case 2:
          data.fileAuditListtId = this.detailItem.id;
          data.saveUserId = this.$store.state.userInfo.id + "";
          break;
        case 3:
          data.fileAuditListtId = this.detailItem.id;
          data.saveUserId = this.$store.state.userInfo.id + "";
          break;
      }
      await post(this.imgUrl, { data: JSON.stringify([data]) })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message.success("上传成功");
            this.$refs.upload.clearFiles();
            this.getImage();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("上传失败");
        });
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod(this.imgUrl + "?Id=" + e.id).then(() => {
                this.getImage();
              });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    // 审核
    changeResult(row, num, state) {
      this.auditResult = num;
      this.detailItem = row;
      if (num == 1) {
        this.$confirm('是否符合要求?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
          this.reqReview();
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });          
        });
        return;
      }
      this.state = state;
      this.getImage();
      this.dialogVisibleresult = true;
      if (this.$route.query.pageIndex != 4) {
        this.lawAsk = row.lawAsk;
      } else {
        this.completion = row.completion;
        this.correctMeasure = row.correctMeasure;
        this.completeTime = row.completeTime;
      }
    },
    reqReview() {
      var detailItem = this.detailItem;
      var data = {
        //现场、文件、考核
        id: detailItem.id,
        questionId: detailItem.questionId,
        question: detailItem.question,
        answer: detailItem.answer,
        lawAsk: this.auditResult == 1 ? detailItem.lawAsk : this.lawAsk,
        auditResult: this.auditResult,
        imagesCount:
          this.auditResult == 1 ? detailItem.imagesCount : this.imgList.length,
      };

      if (this.auditResult != 1 && this.$route.query.pageIndex != 4) {
        var pageId =
          this.$route.query.pageIndex == 1 ? "auditId" : "fileAuditId";
        data[pageId] = this.$route.query.examId * 1;
      }
      put(this.reviewUrl, data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "审核成功",
            });
            this.dialogVisibleresult = false;
            this.getTableList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("审核失败");
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/style/Examine_CSS.css";
.back-title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.dialog > div {
  padding-top: 10px;
}
.dialog-footer {
  text-align: center;
  .el-button + .el-button {
    margin-left: 20px !important;
  }
}
</style>